import React, { useState } from 'react';
import { manuallyCreateUser } from '../../services/user.service';
import { Button, makeStyles, TextField, Typography, Grid, Select, MenuItem } from '@material-ui/core';
import Dialog from '../ui/Dialog';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';

const AddHCPAction = ({ data }) => { 
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmation, setConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [errorInputMessage, setErrorInputMessage] = useState(null);
  const useStyles = makeStyles(() => ({
    field: {
      marginBottom: 10,
      width: '100%'
    },
    select: {
      width: '100%'
    },
    formControl: {
      width: '100%'
    }
  }));
  const classes = useStyles();

  const { user_type, mobile_number, first_name, middle_name, last_name, email } = data;
  const [userDetails, setUserDetails] = useState({
    mobile_number,
    first_name, 
    middle_name, 
    last_name, 
    email,
    user_type
  });

  const onClick = async () => {
    setOpenModal(true);
  };

  const onSubmit = async() => {
    try {
      setLoading(true);
      const { result } = await manuallyCreateUser({
        program_id: `one-novartis-staging`,
        payload: {
          user_type: userDetails.user_type,
          mobile_number: userDetails.mobile_number,
          email: userDetails.email,
          first_name: userDetails.first_name,
          last_name: userDetails.last_name,
          middle_name: userDetails.middle_name,
          full_name: `${userDetails.first_name} ${userDetails.middle_name} ${userDetails.last_name}`
        }
      });

      if (result) {
        setOpenModal(false);
        setConfirmation(true);
      } else {
        setUserDetails({
          mobile_number,
          first_name, 
          middle_name, 
          last_name, 
          email,
          user_type
        });
      }
    } catch(err) {
      if (err && err.inputs) {
        setErrorInputMessage(err.inputs);
      } else if (err && err.message) {
        alert(err.message);
      }
    } finally {
      setLoading(false);
    }
  }

  const onFieldInput = (e) => {
    const { name, value } = e.target;

    setUserDetails(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  const formContent = (
    <>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={12}>
            <TextField  
              label='Mobile Number'
              required={true}
              name='mobile_number' 
              defaultValue={userDetails.mobile_number}
              variant='outlined'
              className={classes.field}
              onChange={onFieldInput} 
              placeholder='60XXXXXXXXX'
            />
            <Typography
              variant='caption'
              display='block'
              gutterBottom
              color='error'
            >
              { errorInputMessage ? errorInputMessage.mobile_number : '' }
            </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
            <Select 
              required={true}
              label='User Type'
              name="user_type"
              variant='outlined' 
              className={classes.field}
              onChange={onFieldInput}
            >
                <MenuItem className={classes.menuItem} key="doctor" value="doctor">
                  Doctor
                </MenuItem>
                <MenuItem className={classes.menuItem} key="pharmacist" value="pharmacist">
                  Pharmacist
                </MenuItem>
            </Select>
            <Typography
              variant='caption'
              display='block'
              gutterBottom
              color='error'
            >
              { errorInputMessage ? errorInputMessage.user_type : '' }
            </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField 
            required={true}
            label='First Name'
            name='first_name' 
            defaultValue={userDetails.first_name}
            variant='outlined'
            className={classes.field}
            onChange={onFieldInput}
            />
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'
          >
          { errorInputMessage ? errorInputMessage.first_name : '' }
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
            <TextField  
              label='Middle Name'
              name='middle_name' 
              defaultValue={userDetails.middle_name}
              variant='outlined'
              className={classes.field}
              onChange={onFieldInput}
            />
            <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'>
              { errorInputMessage ? errorInputMessage.middle_name : '' }
            </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField 
            required={true}
            label='Last Name'
            name='last_name' 
            defaultValue={userDetails.last_name}
            variant='outlined'
            className={classes.field}
            onChange={onFieldInput}
            />
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'
            >
            { errorInputMessage ? errorInputMessage.last_name : '' }
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
         <Grid 
          item xs={12}
        >
          <TextField  
            label='Email Address'
            name='email' 
            defaultValue={userDetails.email}
            variant='outlined'
            className={classes.field}
            onChange={onFieldInput}
          />
          <Typography
            variant='caption'
            display='block'
            gutterBottom
            color='error'
            >
            { errorInputMessage ? errorInputMessage.email : '' }
          </Typography>
        </Grid>
      </Grid>
      <Typography
        variant='caption'
        display='block'
        gutterBottom
        color='error'
      >
        { errorMessage }
      </Typography>
    </>
  );

  const handleClose = (reload) => {
    setOpenModal(false);
    setConfirmation(false);
    setErrorMessage(null);
    setUserDetails({
      mobile_number,
      first_name, 
      middle_name, 
      last_name, 
      email,
      user_type
    });

    if (reload) {
      window.location.reload();
    }
  }

  const formButtons = (
    <>
      <Button onClick={() => handleClose(false)} variant='contained'>
        Close
      </Button>
      <Button onClick={onSubmit} variant='contained' color='secondary'>
        Submit
      </Button>
    </>
  );

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
     <Dialog
      key='create-hcp'
      open={openModal}
      title = 'Create HCP'
      content = {formContent} 
      children = {formButtons} 
      onSubmit = {onSubmit}
      onClose = {() => handleClose(false)} 
      />
     <Dialog 
      key='confirmation'
      open={openConfirmation}
      title = 'Confirmation'
      content = 'HCP successfully created!' 
      children = {(
        <Button onClick={() => handleClose(true)} variant='contained'>
          Close
        </Button>
      )} 
      onSubmit = {onSubmit}
      onClose = {() => handleClose(true)} 
      />
     <Button variant="contained" color="primary" onClick={onClick} 
     style={{ marginRight: 8 }}>
      + ADD HCP</Button>
    </>
  )
}

export default AddHCPAction;
