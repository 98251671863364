import React, { useState } from 'react';
import { updateUserInfo } from '../../services/user.service';
import { Button, makeStyles, TextField, Typography, Grid } from '@material-ui/core';
import Dialog from '../ui/Dialog';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';

const UpdateUserDetails = ({ data }) => { 
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmation, setConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const useStyles = makeStyles(() => ({
    field: {
      marginBottom: 20,
    },
    formControl: {
      width: '100%'
    }
  }));
  const classes = useStyles();

  const { program_id, hcp_id, mobile_number, first_name, middle_name, last_name, 
    dksh_number, organization_branch, email, outlet_name, hospital } = data;
  const [userDetails, setUserDetails] = useState({
    mobile_number,
    first_name, 
    middle_name, 
    last_name, 
    dksh_number, 
    organization_branch,
    email,
    outlet_name,
    hospital
  });

  const onClick = async () => {
    setOpenModal(true);
  };

  const onSubmit = async() => {
    try {
      setLoading(true);
      const res = await updateUserInfo({
        program_id,
        id: hcp_id,
        payload: {
          mobile_number: userDetails.mobile_number,
          metadata: {
            clinic_name: userDetails.organization_branch,
            dksh_number: userDetails.dksh_number,
            outlet_name: userDetails.outlet_name,
            hospital: userDetails.hospital
          },
          email: userDetails.email,
          first_name: userDetails.first_name,
          last_name: userDetails.last_name,
          middle_name: userDetails.middle_name,
          full_name: `${userDetails.first_name} ${userDetails.middle_name} ${userDetails.last_name}`
        }
      })

      if (res) {
        setOpenModal(false);
        setConfirmation(res.message);
      } else {
        setUserDetails({
          mobile_number,
          first_name, 
          middle_name, 
          last_name, 
          dksh_number, 
          organization_branch,
          email,
          outlet_name,
          hospital
        });
      }
      setLoading(false);
    } catch(err) {
      alert(err.message);
      setUserDetails({
        mobile_number,
        first_name, 
        middle_name, 
        last_name, 
        dksh_number, 
        organization_branch,
        email,
        outlet_name,
        hospital
      });
      setLoading(false);
    }
  }

  const onFieldInput = (e) => {
    const { name, value } = e.target;

    setUserDetails(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  const formContent = (
    <>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
            <TextField  
              label='Mobile Number'
              required={true}
              name='mobile_number' 
              defaultValue={userDetails.mobile_number}
              variant='outlined'
              className={classes.field}
              onChange={onFieldInput}
            />
        </Grid>
        <Grid item xs={6}>
          <TextField 
            required={true}
            label='First Name'
            name='first_name' 
            defaultValue={userDetails.first_name}
            variant='outlined'
            className={classes.field}
            onChange={onFieldInput}
            />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
            <TextField  
              label='Middle Name'
              name='middle_name' 
              defaultValue={userDetails.middle_name}
              variant='outlined'
              className={classes.field}
              onChange={onFieldInput}
            />
        </Grid>
        <Grid item xs={6}>
          <TextField 
            required={true}
            label='Last Name'
            name='last_name' 
            defaultValue={userDetails.last_name}
            variant='outlined'
            className={classes.field}
            onChange={onFieldInput}
            />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
         <Grid 
          item xs={6}
        >
          <TextField  
            label='Email Address'
            name='email' 
            defaultValue={userDetails.email}
            variant='outlined'
            className={classes.field}
            onChange={onFieldInput}
          />
        </Grid>
      </Grid>
      {
        program_id && program_id.includes('one-novartis') ? 
        <Grid
        item
        container
        xs={12}
        spacing={2}
        >
          <Grid item xs={6}>
            <TextField 
              required={true}
              label='Outlet Name'
              name='outlet_name' 
              defaultValue={userDetails.outlet_name}
              variant='outlined'
              className={classes.field}
              onChange={onFieldInput}
              />
          </Grid>
          <Grid item xs={6}>
            <TextField 
              required={true}
              label='Hospital'
              name='hospital' 
              defaultValue={userDetails.hospital}
              variant='outlined'
              className={classes.field}
              onChange={onFieldInput}
              />
          </Grid>
        </Grid> : <></>
      }

      <Grid
        item
        container
        xs={12}
        spacing={2}
      >
        <Grid item xs={6}>
          <TextField 
            required={true}
            label='DKSH Number'
            name='dksh_number' 
            defaultValue={userDetails.dksh_number}
            variant='outlined'
            className={classes.field}
            onChange={onFieldInput}
            />
        </Grid>
        {
          program_id && program_id.includes('one-novartis') ? <></> :
          <Grid item xs={6}>
            <TextField  
              label='Organization Branch'
              name='organization_branch' 
              defaultValue={userDetails.organization_branch}
              variant='outlined'
              className={classes.field}
              onChange={onFieldInput}
            />
          </Grid>
        }
      </Grid>
      <Typography
        variant='caption'
        display='block'
        gutterBottom
        color='error'
      >
        { errorMessage }
      </Typography>
    </>
  );

  const handleClose = (reload) => {
    setOpenModal(false);
    setConfirmation(false);
    setErrorMessage(null);
    setUserDetails({
      mobile_number,
      first_name, 
      middle_name, 
      last_name, 
      dksh_number, 
      organization_branch,
      email,
      outlet_name,
      hospital
    });
    if (reload) {
      window.location.reload();
    }
  }

  const formButtons = (
    <>
      <Button onClick={() => handleClose(false)} variant='contained'>
        Close
      </Button>
      <Button onClick={onSubmit} variant='contained' color='secondary'>
      Submit
      </Button>
    </>
  );

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
     <Dialog
      key='update-user-details'
      open={openModal}
      title = 'Update User Details'
      content = {formContent} 
      children = {formButtons} 
      onSubmit = {onSubmit}
      onClose = {() => handleClose(false)} 
      />
     <Dialog 
      key='confirmation'
      open={openConfirmation}
      title = 'Confirmation'
      content = 'User details successfully updated!' 
      children = {(
        <Button onClick={() => handleClose(true)} variant='contained'>
          Close
        </Button>
      )} 
      onSubmit = {onSubmit}
      onClose = {() => handleClose(true)} 
      />
     <Button variant="contained" onClick={onClick} style={{ marginRight: 8 }}>Update Details</Button>
    </>
  )
}

export default UpdateUserDetails;
